import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { DecimalPointSeparate } from '@app/components/Shared/Inputs/DecimalPointSeparate';
import { NumericTextField } from '@app/components/Shared/Inputs/NumericTextField';
import {
  ProductAreaType,
  ProductBuildingStatus,
  ProductCompletionDateType,
  ProductCompletionDateTypeKey,
  ProductDeliveryDateType,
  ProductDeliveryDateTypeKey,
  ProductFloorPlanType,
  ProductReform,
  ProductSalesHouseStatus,
} from '@app/types/catalog';
import { Common } from '@app/types/common';
import { MONTH_SEASON_OPTIONS, RANGE_TYPE_OPTIONS } from '@app/utils/constants';

const BUILDING_AREA_TYPE_OPTIONS = [
  { label: '登記', value: ProductAreaType.REGISTRY },
  { label: '実測', value: ProductAreaType.SURVEY },
  { label: '未選択', value: Common.EMPTY },
];
const SALES_HOUSE_STATUS_OPTIONS = [
  { label: '未定', value: ProductSalesHouseStatus.PENDING },
  { label: '予定・確定', value: ProductSalesHouseStatus.PLAN },
];
const COMPLETION_DATE_TYPE_OPTIONS = [
  { label: '完成予定', value: ProductCompletionDateType.COMPLETION_PLAN },
  { label: '完成済み', value: ProductCompletionDateType.COMPLETED },
  { label: '契約後', value: ProductCompletionDateType.CONTRACTED },
];
const DELIVERY_DATE_TYPE_OPTIONS = [
  { label: '即引渡可', value: ProductDeliveryDateType.IMMEDIATE },
  { label: '相談', value: ProductDeliveryDateType.CONSULTATION },
  { label: '指定有り', value: ProductDeliveryDateType.SPECIFIC },
  { label: '契約後', value: ProductDeliveryDateType.CONTRACTED },
];
const BUILDING_STATUS_OPTIONS = [
  { label: '新築', value: ProductBuildingStatus.NEW },
  { label: '未入居', value: ProductBuildingStatus.UNOCCUPIED },
  { label: '中古', value: ProductBuildingStatus.USED },
];
const REFORM_OPTIONS = [
  { label: '無', value: ProductReform.NONE },
  { label: '済', value: ProductReform.COMPLETED },
  { label: '完了予定', value: ProductReform.COMPLETION_PLAN },
];
const FLOOR_PLAN_TYPE_OPTIONS = [
  ProductFloorPlanType.K,
  ProductFloorPlanType.DK,
  ProductFloorPlanType.LDK,
  ProductFloorPlanType.LK,
  ProductFloorPlanType.KK,
  ProductFloorPlanType.DKK,
  ProductFloorPlanType.LKK,
  ProductFloorPlanType.DDKK,
  ProductFloorPlanType.LLKK,
  ProductFloorPlanType.LDKK,
  ProductFloorPlanType.LDDKK,
  ProductFloorPlanType.LLDKK,
  ProductFloorPlanType.LLDDKK,
];

export interface BuildingInfoFormSectionProps {
  onChangeSalesHouseCount?: (value: string) => void;
}
export function BuildingInfoFormSection({
  onChangeSalesHouseCount,
}: BuildingInfoFormSectionProps): ReactElement {
  const {
    clearErrors,
    control,
    formState: { errors },
    resetField,
    setValue,
    trigger,
  } = useFormContext();
  const deliveryDateType = useWatch({ control, name: 'deliveryDateType' });
  const salesHouseStatus = useWatch({ control, name: 'salesHouseStatus' });
  const completionDateType = useWatch({ control, name: 'completionDateType' });
  const floorPlanRoomsMax = useWatch({ control, name: 'floorPlanRoomsMax' });
  const floorPlanTypeMax = useWatch({ control, name: 'floorPlanTypeMax' });
  const [isSalesHouseCount, setSalesHouseCount] = useState(false);

  useEffect(() => {
    if (salesHouseStatus === ProductSalesHouseStatus.PENDING) {
      setSalesHouseCount(true);
      clearErrors('salesHouseCount');
      setValue('salesHouseCount', '');
    } else {
      setSalesHouseCount(false);
    }
  }, [salesHouseStatus, clearErrors, setValue]);

  useEffect(() => {
    void trigger('floorPlanRangeType');
    void trigger('floorPlanRoomsMax');
  }, [floorPlanTypeMax, trigger]);

  return (
    <Stack spacing={3}>
      <Typography variant="h5">建物情報</Typography>
      <Stack>
        <FormLabel focused={false} className="required-label">
          建物面積
        </FormLabel>
        <Controller
          name="buildingAreaType"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup {...field} row>
                {BUILDING_AREA_TYPE_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </>
          )}
        />
        <Stack direction="row" spacing={0.5} alignItems="center">
          <DecimalPointSeparate
            field1stName="minBuildingArea.0"
            field2ndName="minBuildingArea.1"
            hideErrorMessage
          />
          <Typography>㎡</Typography>
          <Controller
            name="buildingAreaRangeType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value)
                    return (
                      RANGE_TYPE_OPTIONS.find((o) => o.value === value)
                        ?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {RANGE_TYPE_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <DecimalPointSeparate
            field1stName="maxBuildingArea.0"
            field2ndName="maxBuildingArea.1"
            hideErrorMessage
          />
          <Typography>㎡</Typography>
        </Stack>
        {Array.from(
          new Set([
            get(errors, 'minBuildingArea.0.message', ''),
            get(errors, 'minBuildingArea.1.message', ''),
            get(errors, 'maxBuildingArea.0.message', ''),
            get(errors, 'maxBuildingArea.1.message', ''),
            get(errors, 'buildingAreaRangeType.message', ''),
          ])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          販売戸数
        </FormLabel>
        <Controller
          name="salesHouseStatus"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup {...field} row>
                {SALES_HOUSE_STATUS_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </>
          )}
        />
        <div>
          <Controller
            name="salesHouseCount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <NumericTextField
                    {...field}
                    error={!!error}
                    disabled={isSalesHouseCount}
                    onBlur={(e) => onChangeSalesHouseCount?.(e.target.value)}
                  />
                  <Typography>戸・区画</Typography>
                </Stack>
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </div>
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          総戸数
        </FormLabel>
        <div>
          <Controller
            name="totalHouseCount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <NumericTextField {...field} error={!!error} />
                  <Typography>戸・区画</Typography>
                </Stack>
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </div>
      </FormControl>
      <Stack>
        <FormLabel focused={false} className="required-label">
          間取りタイプ
        </FormLabel>
        <Stack direction="row" spacing={1} alignItems="center">
          <Controller
            name="floorPlanRoomsMin"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField {...field} error={!!error} sx={{ width: 60 }} />
            )}
          />
          <Controller
            name="floorPlanTypeMin"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={!!error}
                displayEmpty
                sx={{ width: 100 }}
              >
                {FLOOR_PLAN_TYPE_OPTIONS.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="floorPlanRangeType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={!!error}
                displayEmpty
                renderValue={(value: string) => {
                  if (value)
                    return (
                      RANGE_TYPE_OPTIONS.find((o) => o.value === value)
                        ?.label || ''
                    );
                  return (
                    <Typography variant="body2" color="secondary">
                      選択してください
                    </Typography>
                  );
                }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {RANGE_TYPE_OPTIONS.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <Controller
            name="floorPlanRoomsMax"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                error={!!error}
                onChange={(value) => {
                  field.onChange(value);
                  if (!value || (!floorPlanRoomsMax && value)) {
                    void trigger('floorPlanRangeType');
                    void trigger('floorPlanTypeMax');
                  }
                }}
                sx={{ width: 60 }}
              />
            )}
          />
          <Controller
            name="floorPlanTypeMax"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                error={!!error}
                displayEmpty
                sx={{ width: 100 }}
              >
                <MenuItem value="">{'　'}</MenuItem>
                {FLOOR_PLAN_TYPE_OPTIONS.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Stack>
        {Array.from(
          new Set([
            errors.floorPlanRoomsMin?.message,
            errors.floorPlanTypeMin?.message,
            errors.floorPlanRangeType?.message,
            errors.floorPlanRoomsMax?.message,
            errors.floorPlanTypeMax?.message,
          ])
        )
          .filter((m) => !!m)
          .map((message, index) => (
            <FormHelperText key={index} error>
              {message as string}
            </FormHelperText>
          ))}
        <Controller
          name="floorPlanTypeAdditional"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error?.message}
              sx={{ mt: 1 }}
            />
          )}
        />
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          完成時期（築年月）
        </FormLabel>
        <Stack direction="row">
          <Controller
            name="completionDateType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box mt={0.75}>
                <RadioGroup
                  {...field}
                  row
                  onChange={(e) => {
                    const value = e.target
                      .value as ProductCompletionDateTypeKey;
                    if (
                      (completionDateType !==
                        ProductCompletionDateType.CONTRACTED &&
                        value === ProductCompletionDateType.CONTRACTED) ||
                      (completionDateType ===
                        ProductCompletionDateType.CONTRACTED &&
                        value !== ProductCompletionDateType.CONTRACTED)
                    ) {
                      resetField('completionDateYear', { defaultValue: '' });
                      resetField('completionDateMonth', { defaultValue: '' });
                      resetField('completionSeason', { defaultValue: '' });
                    }
                    field.onChange(value);
                  }}
                >
                  {COMPLETION_DATE_TYPE_OPTIONS.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <Typography variant="body2">{option.label}</Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          {completionDateType === ProductCompletionDateType.CONTRACTED && (
            <Controller
              name="completionDateMonth"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <NumericTextField
                      {...field}
                      error={!!error}
                      maxLength={2}
                      sx={{ width: 100 }}
                    />
                    <Typography>ヵ月</Typography>
                  </Stack>
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          )}
        </Stack>
        {completionDateType !== ProductCompletionDateType.CONTRACTED && (
          <Stack direction="row" spacing={0.5}>
            <Controller
              name="completionDateYear"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <NumericTextField {...field} error={!!error} maxLength={4} />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Typography pt={1.25}>年</Typography>
            <Controller
              name="completionDateMonth"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <NumericTextField {...field} error={!!error} maxLength={2} />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Typography pt={1.25}>月</Typography>
          </Stack>
        )}
        {completionDateType !== ProductCompletionDateType.CONTRACTED && (
          <Controller
            name="completionSeason"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <RadioGroup {...field} row>
                  {MONTH_SEASON_OPTIONS.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <Typography variant="body2">{option.label}</Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </div>
            )}
          />
        )}
      </Stack>
      <Stack>
        <FormLabel focused={false} className="required-label">
          引渡可能時期
        </FormLabel>
        <Stack direction="row">
          <Controller
            name="deliveryDateType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <RadioGroup
                  {...field}
                  row
                  onChange={(e) => {
                    const value = e.target.value as ProductDeliveryDateTypeKey;
                    field.onChange(value);
                    if (
                      value === ProductDeliveryDateType.IMMEDIATE ||
                      value === ProductDeliveryDateType.CONSULTATION
                    ) {
                      resetField('deliveryDateYear', { defaultValue: '' });
                      resetField('deliveryDateMonth', { defaultValue: '' });
                      resetField('deliverySeason', { defaultValue: '' });
                    } else if (value === ProductDeliveryDateType.CONTRACTED) {
                      resetField('deliveryDateYear', { defaultValue: '' });
                      resetField('deliveryDateMonth', { defaultValue: '' });
                      resetField('deliverySeason', { defaultValue: '' });
                      resetField('deliveryNote', { defaultValue: '' });
                    }
                  }}
                >
                  {DELIVERY_DATE_TYPE_OPTIONS.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <Typography variant="body2">{option.label}</Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                {error && (
                  <FormHelperText error>
                    {error.message as string}
                  </FormHelperText>
                )}
              </div>
            )}
          />
          {deliveryDateType === ProductDeliveryDateType.CONTRACTED && (
            <Controller
              name="deliveryDateMonth"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <NumericTextField
                      {...field}
                      error={!!error}
                      maxLength={2}
                      sx={{ width: 100 }}
                    />
                    <Typography>ヵ月</Typography>
                  </Stack>
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          )}
        </Stack>
        {deliveryDateType !== ProductCompletionDateType.CONTRACTED && (
          <Stack direction="row" spacing={0.5}>
            <Controller
              name="deliveryDateYear"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <NumericTextField
                    {...field}
                    error={!!error}
                    maxLength={4}
                    disabled={
                      deliveryDateType === ProductDeliveryDateType.IMMEDIATE ||
                      deliveryDateType === ProductDeliveryDateType.CONSULTATION
                    }
                  />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Typography pt={1.25}>年</Typography>
            <Controller
              name="deliveryDateMonth"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <NumericTextField
                    {...field}
                    error={!!error}
                    maxLength={2}
                    disabled={
                      deliveryDateType === ProductDeliveryDateType.IMMEDIATE ||
                      deliveryDateType === ProductDeliveryDateType.CONSULTATION
                    }
                  />
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Typography pt={1.25}>月</Typography>
          </Stack>
        )}
        {deliveryDateType !== ProductCompletionDateType.CONTRACTED && (
          <>
            <Controller
              name="deliverySeason"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <RadioGroup {...field} row>
                    {MONTH_SEASON_OPTIONS.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {error && (
                    <FormHelperText error>
                      {error.message as string}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
            <Controller
              name="deliveryNote"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="引渡可能時期その他を入力"
                />
              )}
            />
          </>
        )}
      </Stack>
      <FormControl fullWidth>
        <FormLabel focused={false} className="required-label">
          建物状況
        </FormLabel>
        <Controller
          name="buildingStatus"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {BUILDING_STATUS_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>構造・工法</FormLabel>
        <Controller
          name="buildingSystem"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>例：木造2階建</FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>施工会社</FormLabel>
        <Controller
          name="constructionCompany"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false} className="condition-required-label">
          建築確認番号
        </FormLabel>
        <Controller
          name="buildingConfirmationNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          )}
        />
        <FormHelperText>
          ※
          未完成物件は建築確認番号が必須です。番号が複数ある場合は「第〇〇号他」と入力して下さい。
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>リフォーム：外装</FormLabel>
        <Controller
          name="reformExterior"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {REFORM_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel focused={false}>リフォーム：内装</FormLabel>
        <Controller
          name="reformInterior"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <RadioGroup {...field} row>
                {REFORM_OPTIONS.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">{option.label}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
              {error && (
                <FormHelperText error>{error.message as string}</FormHelperText>
              )}
            </div>
          )}
        />
      </FormControl>
    </Stack>
  );
}
