import {
  Box,
  Card,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { endOfMonth, format } from 'date-fns';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { getOrdersByOrgId, updateOrder } from '@app/adapter/order-service';
import { OrderCsvDownloadButton } from '@app/components/Shared/Button/OrderCsvDownloadButton';
import { DatePicker } from '@app/components/Shared/Inputs/DatePicker';
import {
  OptionProps,
  StatusSelect,
} from '@app/components/Shared/Inputs/StatusSelect';
import { ListTable, paginationModel } from '@app/components/Shared/ListTable';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import { SimpleDialog } from '@app/components/Shared/SimpleDialog';
import { organizationSelector } from '@app/domain/organization';
import { useAppContext } from '@app/hooks/appContext';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { OrderSearchForm, OrderSearchFormData } from '@app/schemas/order';
import { theme } from '@app/theme';
import {
  Order,
  OrderStatus,
  OrderStatusKey,
  OrderType,
  OrderTypeKey,
} from '@app/types/order';
import { isError } from '@app/utils/error';
import { convertUtcToJp, dateConvertToFormat } from '@app/utils/format';
import { convertPostalCode } from '@app/utils/index';
import { getCategoryValueByOrderType } from '@app/utils/order';

const TABS = [
  { label: '未対応リスト', value: OrderStatus.PENDING },
  { label: '対応・商談中', value: OrderStatus.PROCESSING },
] as const;
const SORT_OPTIONS = [
  { label: '新しい順', value: 'createdAt desc' },
  { label: '古い順', value: 'createdAt' },
] as const;
const PENDING_STATUS_OPTIONS = [
  {
    label: '未対応',
    severity: 'error',
    value: OrderStatus.PENDING,
  },
  {
    label: '対応',
    severity: 'success',
    value: OrderStatus.PROCESSING,
  },
] as OptionProps[];
const PROCESSING_STATUS_OPTIONS = [
  {
    bgcolor: theme.customPalette.gray5,
    label: '未選択',
    value: OrderStatus.PROCESSING,
  },
  {
    label: '承認',
    severity: 'success',
    value: OrderStatus.ACCEPTED,
  },
  {
    label: '否認',
    severity: 'error',
    value: OrderStatus.CANCELED,
  },
] as OptionProps[];

export interface OrderListProps {
  orderType: string;
  title?: string;
}

export function OrderList({
  orderType,
  title = '',
}: OrderListProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const { fetchOrderStatus } = useAppContext();
  const organizationState = useRecoilValue(organizationSelector);
  const [tab, setTab] = useState<string>(TABS[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setShowDialog] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState();

  const isVisitorReserve: boolean = useMemo(() => {
    return (
      [
        OrderType.EXHIBITION_VISITOR_RESERVE,
        OrderType.PROPERTY_VISITOR_RESERVE,
      ] as string[]
    ).includes(orderType);
  }, [orderType]);

  const isExhibition: boolean = useMemo(() => {
    return (
      [
        OrderType.EXHIBITION_VISITOR_RESERVE,
        OrderType.EXHIBITION_REQUEST_DOC,
      ] as string[]
    ).includes(orderType);
  }, [orderType]);

  const statusOptions = useMemo(() => {
    return tab === OrderStatus.PENDING
      ? PENDING_STATUS_OPTIONS
      : PROCESSING_STATUS_OPTIONS;
  }, [tab]);

  // Search form
  const { control } = useForm<OrderSearchFormData>(OrderSearchForm);
  const formWatch = useWatch({ control });
  const [keyword, setKeyword] = useState('');

  // Table data
  const [rows, setRows] = useState<Order[]>([]);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(paginationModel);
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '名前',
      renderCell: (params) => (
        <Box className="ow-break-word">{params.row.customer?.name || ''}</Box>
      ),
      sortable: false,
      width: 160,
    },
    {
      field: 'email',
      flex: 1,
      headerName: 'メールアドレス',
      minWidth: 160,
      renderCell: (params) => (
        <Box className="ow-break-word">{params.row.customer?.email || ''}</Box>
      ),
      sortable: false,
    },
    {
      field: 'phone',
      headerName: '電話番号',
      renderCell: (params) => params.row.customer?.phone || '',
      sortable: false,
      width: 130,
    },
    {
      field: 'productName',
      headerName: `見学する${isExhibition ? '住宅展示場' : '分譲'}`,
      renderCell: (params) => (
        <Box className="text-ellipsis">
          {params.row.lineItems?.[0].productName || ''}
        </Box>
      ),
      sortable: false,
      width: 180,
    },
    {
      field: 'createdAt',
      headerName: isVisitorReserve ? '反響日時' : '請求日時',
      renderCell: (params) =>
        format(convertUtcToJp(params.row.createdAt), 'yyyy年M月d日 HH:mm'),
      sortable: false,
      width: 160,
    },
    {
      field: 'status',
      headerName: tab === OrderStatus.PENDING ? 'ステータス' : '請求ステータス',
      renderCell: (params) => (
        <StatusSelect
          ref={undefined}
          options={statusOptions}
          value={params.row.status}
          fullWidth
          onChange={(e) => {
            const status = e.target.value as OrderStatusKey;
            if (status === OrderStatus.PROCESSING) {
              setConfirmOrder(params.row.id);
            } else {
              void updateOrderStatus(params.row.id, status);
            }
          }}
          sx={{ '.MuiInputBase-input': { padding: 1 } }}
        />
      ),
      sortable: false,
      width: 110,
    },
  ];

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () => ({
      productName: isVisitorReserve,
    }),
    [isVisitorReserve]
  );

  const updateOrderStatus = async (id: string, status: OrderStatusKey) => {
    if (!id || !organizationState) return;
    try {
      await updateOrder(organizationState.id, id, {
        status,
      });
      setSnackbar(true, '更新しました', 'success');
      await fetchOrders();
      await fetchOrderStatus();
    } catch (e) {
      setSnackbar(true, '更新に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    }
  };

  const fetchOrders = async () => {
    if (!organizationState) return;
    try {
      setIsLoading(true);
      const { page, pageSize } = currentPaginationModel;

      let createdAtUntil = '';
      if (formWatch.createdAtUntil) {
        createdAtUntil = dateConvertToFormat(
          endOfMonth(new Date(formWatch.createdAtUntil)),
          'yyyy-MM-dd'
        );
      }

      const { data } = await getOrdersByOrgId(organizationState.id, {
        expand: 'customer.user',
        filter: {
          createdAt: [formWatch.createdAtSince || '', createdAtUntil],
          keyword,
          orderType,
          statuses:
            formWatch.status ||
            (tab === OrderStatus.PENDING
              ? tab
              : [
                  OrderStatus.PROCESSING,
                  OrderStatus.ACCEPTED,
                  OrderStatus.CANCELED,
                ]),
        },
        orderBy: formWatch.sort,
        page,
        pageSize,
      });
      setRows(data.value);
      setRowTotal(data.total);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaginationModel, formWatch, keyword, tab, orderType]);

  const [selectedItem, setSelectedItem] = useState<Order>();
  const handleClickRow: GridEventListener<'rowClick'> = (params) => {
    setSelectedItem(params.row);
    setShowDialog(true);
  };

  return (
    <>
      <Tabs value={tab} onChange={(e, value) => setTab(value)}>
        {TABS.map(({ label, value }, index) => (
          <Tab
            key={index}
            label={<Typography fontWeight={700}>{label}</Typography>}
            value={value}
          />
        ))}
      </Tabs>
      <Divider sx={{ mb: 4.5 }} />
      <Stack direction="row" justifyContent="space-between">
        <PageTitle title={title} buttonHidden />
        <OrderCsvDownloadButton
          organizationId={organizationState?.id}
          productCategory={getCategoryValueByOrderType(
            orderType as OrderTypeKey
          )}
          orderType={orderType as OrderTypeKey}
          status={tab as OrderStatusKey}
        />
      </Stack>
      <Card>
        <Stack direction="row" spacing={1.5} p={1.5}>
          <SearchTextBox value={keyword} onSubmit={setKeyword} />
          {tab === OrderStatus.PROCESSING && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="body3" noWrap>
                ステータス
              </Typography>
              <Controller
                name="status"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                      renderValue={(value: string) => {
                        if (value)
                          return (
                            statusOptions.find((o) => o.value === value)
                              ?.label || ''
                          );
                        return (
                          <Typography variant="body2" color="secondary">
                            すべて
                          </Typography>
                        );
                      }}
                      sx={{ '.MuiSelect-select': { py: 1.25 }, width: 140 }}
                    >
                      <MenuItem value="">すべて</MenuItem>
                      {statusOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          )}
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body3" noWrap>
              反響期間
            </Typography>
            <Controller
              name="createdAtSince"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  actions={['clear']}
                  inputRef={field.ref}
                  error={!!error}
                  size="small"
                  placeholder="年月"
                  datePicker={{
                    inputFormat: 'yyyy年M月',
                    openTo: 'month',
                    value: field.value,
                    views: ['year', 'month'],
                  }}
                  readOnly
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  sx={{ width: '140px' }}
                />
              )}
            />
            <Typography variant="body3">〜</Typography>
            <Controller
              name="createdAtUntil"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  actions={['clear']}
                  inputRef={field.ref}
                  error={!!error}
                  size="small"
                  placeholder="年月"
                  datePicker={{
                    inputFormat: 'yyyy年M月',
                    openTo: 'month',
                    value: field.value,
                    views: ['year', 'month'],
                  }}
                  readOnly
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  sx={{ width: '140px' }}
                />
              )}
            />
          </Stack>
          <Box flexGrow={1} />
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body3" noWrap>
              並び順
            </Typography>
            <Controller
              name="sort"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <Select
                    {...field}
                    error={!!error}
                    size="small"
                    displayEmpty
                    sx={{ '.MuiSelect-select': { py: 1.25 }, width: 140 }}
                  >
                    {SORT_OPTIONS.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Stack>
        </Stack>
        <ListTable
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          loading={isLoading}
          paginationModel={currentPaginationModel}
          columnVisibilityModel={columnVisibilityModel}
          className="b-radius-top-less"
          initialState={{
            pagination: { paginationModel },
          }}
          onPaginationModelChange={setCurrentPaginationModel}
          onRowClick={handleClickRow}
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Card>
      <SimpleDialog
        open={isShowDialog}
        title="反響詳細"
        content={
          <Stack spacing={1}>
            <div>
              <Typography variant="body3">名前</Typography>
              <Typography>{selectedItem?.customer.name || ''}</Typography>
            </div>
            <div>
              <Typography variant="body3">フリガナ</Typography>
              <Typography>{selectedItem?.customer.nameKana || ''}</Typography>
            </div>
            <div>
              <Typography variant="body3">年齢</Typography>
              <Typography>
                {selectedItem?.customer.age
                  ? `${selectedItem?.customer.age} 歳`
                  : ''}
              </Typography>
            </div>
            <div>
              <Typography variant="body3">電話番号</Typography>
              <Typography>{selectedItem?.customer.phone || ''}</Typography>
            </div>
            <div>
              <Typography variant="body3">メールアドレス</Typography>
              <Typography>{selectedItem?.customer.email || ''}</Typography>
            </div>
            <div>
              <Typography variant="body3">住所</Typography>
              <Typography>
                {convertPostalCode(
                  selectedItem?.customFields?.postalCode,
                  true
                )}
                <br />
                {[
                  selectedItem?.customer.addressLine1 || '',
                  selectedItem?.customer.addressLine2 || '',
                  selectedItem?.customer.addressLine3 || '',
                  selectedItem?.customFields?.addressLine4 || '',
                  ` ${selectedItem?.customFields?.addressLine5 || ''}`,
                ].join('')}
              </Typography>
            </div>
            {selectedItem?.customFields?.desiredReservationDate?.map(
              (date, index) => (
                <div>
                  <Typography variant="body3">
                    第{index + 1}予約希望日時
                  </Typography>
                  <Typography>
                    {dateConvertToFormat(date, 'yyyy年MM月dd日(eee) HH:mm')}
                  </Typography>
                </div>
              )
            )}
            {selectedItem?.customFields?.customForms?.map((form) => (
              <div>
                <Typography variant="body3">{form.question}</Typography>
                <Typography>
                  {form.answer
                    .map((a) => (a === 'undefined' ? '未回答' : a || '未回答'))
                    .join(',')}
                </Typography>
              </div>
            ))}
            <div>
              <Typography variant="body3">建築予定地</Typography>
              <Typography>
                {selectedItem?.customFields?.buildingLocation || ''}
              </Typography>
            </div>
            <div>
              <Typography variant="body3">メモ</Typography>
              <Typography>{selectedItem?.customFields?.note || ''}</Typography>
            </div>
          </Stack>
        }
        fullWidth
        onClickCancel={() => setShowDialog(false)}
      />
      <SimpleDialog
        open={!!confirmOrder}
        title="確認"
        content={
          <div>
            未対応の反響を対応中に進めます。
            <br />
            一度対応中に変更すると元に戻せませんのでご注意ください。
          </div>
        }
        submitButton="変更する"
        fullWidth
        onClickSubmit={() => {
          confirmOrder &&
            void updateOrderStatus(confirmOrder, OrderStatus.PROCESSING);
          setConfirmOrder(undefined);
        }}
        onClickCancel={() => setConfirmOrder(undefined)}
      />
    </>
  );
}
