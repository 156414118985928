import { useCallback, useEffect, useState } from 'react';

import { getAttributes } from '@app/adapter/catalog-service';
import { Attribute, AttributeGroupNameKey } from '@app/types/catalog';
import { isError } from '@app/utils/error';

export const useAttribute = (groupName?: AttributeGroupNameKey) => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const getAttributeGroups = useCallback(async () => {
    try {
      const { data } = await getAttributes({
        filter: { groupName },
        pageSize: 100,
      });
      setAttributes(data.value);
    } catch (error) {
      if (isError(error)) {
        console.error(error.message);
      }
    }
  }, [groupName]);

  useEffect(() => {
    if (groupName) {
      void getAttributeGroups();
    }
  }, [groupName, getAttributeGroups]);

  return {
    attributes,
    getAttributeGroups,
  };
};
