import { startOfDay, isBefore, addDays } from 'date-fns';
import { get, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue, useRecoilState } from 'recoil';

import {
  createProduct,
  updateProduct,
  uploadBlob,
} from '@app/adapter/catalog-service';
import { isLoading } from '@app/domain/app';
import { categoryByActiveSelector } from '@app/domain/catalog';
import {
  MainVisualDefaultValues,
  MainVisualForm,
  MainVisualFormKey,
  PropertyAroundForm as AroundForm,
  PropertyBasicInfoForm as BasicInfoForm,
  PropertyBuildingInfoForm as BuildingInfoForm,
  PropertyCompanyInfoForm as CompanyInfoForm,
  PropertyEquipmentsForm as EquipmentsForm,
  PropertyEventInfoForm as EventInfoForm,
  PropertyExteriorForm as ExteriorForm,
  PropertyFloorPlanForm as FloorPlanForm,
  PropertyPlotMapForm as PlotMapForm,
  PropertyFloorPlanEditFormKey,
  PropertyFormKey,
  PropertyInteriorForm as InteriorForm,
  PropertyLandInfoForm as LandInfoForm,
  PropertyLimitationForm as LimitationForm,
  PropertyOverviewForm as OverviewForm,
  PropertyPriceForm as PriceForm,
  PropertyFormDefaultValues as DefaultValues,
  VariantEditFormKey,
} from '@app/schemas/catalog';
import {
  Product,
  ProductCategory,
  ProductCompletionDateType,
  ProductCreate,
  ProductDeliveryDateType,
  ProductGeoCoordinatesFieldType,
  ProductRangeType,
  ProductSalesStatus,
  ProductUpdate,
  ProductVariantCreate,
  ProductVariantSku,
  ProductVariantSkuKey,
  VariantCustomFields,
} from '@app/types/catalog';
import { DateType, Common } from '@app/types/common';
import { base64ToBlob } from '@app/utils/base64';
import {
  getPublicationSince,
  getPublicationUntil,
  getVariantsBySku,
} from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { dateConvertToFormat, isFormatDay } from '@app/utils/format';

export const useProductPropertyForm = () => {
  const [, setLoadingState] = useRecoilState(isLoading);
  const [organizationId, setOrganizationId] = useState<string>();
  // カテゴリ一覧
  const categories = useRecoilValue(categoryByActiveSelector);
  // フォーム群
  const overviewForm = useForm<PropertyFormKey['overview']>(OverviewForm);
  const mainVisualForm = useForm<MainVisualFormKey>(MainVisualForm);
  const basicInfoForm = useForm<PropertyFormKey['basicInfo']>(BasicInfoForm);
  const priceForm = useForm<PropertyFormKey['price']>(PriceForm);
  const landInfoForm = useForm<PropertyFormKey['landInfo']>(LandInfoForm);
  const buildingInfoForm =
    useForm<PropertyFormKey['buildingInfo']>(BuildingInfoForm);
  const companyInfoForm =
    useForm<PropertyFormKey['companyInfo']>(CompanyInfoForm);
  const limitationForm = useForm<PropertyFormKey['limitation']>(LimitationForm);
  const eventInfoForm = useForm<PropertyFormKey['eventInfo']>(EventInfoForm);
  const aroundForm = useForm<PropertyFormKey['around']>(AroundForm);
  const equipmentForm = useForm<PropertyFormKey['equipment']>(EquipmentsForm);
  const exteriorForm = useForm<PropertyFormKey['exterior']>(ExteriorForm);
  const floorPlanForm = useForm<PropertyFormKey['floorPlan']>(FloorPlanForm);
  const interiorForm = useForm<PropertyFormKey['interior']>(InteriorForm);
  const plotMapForm = useForm<PropertyFormKey['plotMap']>(PlotMapForm);

  const setFormValues = (data?: Product) => {
    const customFields = data?.customFields || {};

    overviewForm.reset({
      additionalInformation:
        data?.additionalInformation ||
        DefaultValues.overview.additionalInformation,
      description: data?.description || DefaultValues.overview.description,
      features: data?.features || DefaultValues.overview.features,
      name: data?.name || DefaultValues.overview.name,
      previewAdComment:
        customFields?.previewAdComment ||
        DefaultValues.overview.previewAdComment,
      propertyCategory:
        customFields?.propertyCategory ||
        DefaultValues.overview.propertyCategory,
      salesStatus:
        customFields?.salesStatus || DefaultValues.overview.salesStatus,
    });

    mainVisualForm.reset({
      mainVisuals: data?.images || MainVisualDefaultValues.mainVisuals,
    });

    // 交通情報
    const transportSub = Array.from({ length: 2 }, (h, i) => {
      return {
        line: get(customFields, `transportSub${i + 1}Line`, ''),
        mean: get(customFields, `transportSub${i + 1}Mean`, Common.EMPTY),
        nearest: get(customFields, `transportSub${i + 1}Nearest`, ''),
        note: get(customFields, `transportSub${i + 1}Note`, ''),
        timeMax: String(get(customFields, `transportSub${i + 1}TimeMax`) || ''),
        timeMin: String(get(customFields, `transportSub${i + 1}TimeMin`) || ''),
      };
    }).filter((i) => i.line);
    basicInfoForm.reset({
      addressLine1: data?.addressLine1 || DefaultValues.basicInfo.addressLine1,
      addressLine2: data?.addressLine2 || DefaultValues.basicInfo.addressLine2,
      addressLine3: data?.addressLine3 || DefaultValues.basicInfo.addressLine3,
      addressLine3HiddenFlag:
        customFields.addressLine3HiddenFlag ||
        DefaultValues.basicInfo.addressLine3HiddenFlag,
      addressLine4:
        customFields.addressLine4 || DefaultValues.basicInfo.addressLine4,
      cityId: data?.locationIds?.[1] || DefaultValues.basicInfo.cityId,
      contactAdditional:
        customFields.contactAdditional ||
        DefaultValues.basicInfo.contactAdditional,
      contactAddress:
        customFields.contactAddress || DefaultValues.basicInfo.contactAddress,
      contactPhoneNumber:
        customFields.contactPhoneNumber?.split('-') ||
        DefaultValues.basicInfo.contactPhoneNumber,
      geoCode: data?.geoCoordinatesField?.coordinates?.[0]
        ? {
            lat: data?.geoCoordinatesField?.coordinates[0],
            lng: data?.geoCoordinatesField?.coordinates[1],
          }
        : DefaultValues.basicInfo.geoCode,
      lotteryDate:
        dateConvertToFormat(
          customFields.lotteryDate,
          isFormatDay(customFields.lotteryDate) ? 'yyyy/MM/dd' : 'yyyy/MM'
        ) || DefaultValues.basicInfo.lotteryDate,
      lotteryDateSeason:
        customFields.lotteryDateSeason ||
        DefaultValues.basicInfo.lotteryDateSeason,
      lotteryDateType: customFields.lotteryDate
        ? isFormatDay(customFields.lotteryDate)
          ? DateType.DAY
          : DateType.MONTH
        : DefaultValues.basicInfo.lotteryDateType,
      postalCode: data?.postalCode || DefaultValues.basicInfo.postalCode,
      prefectureId:
        data?.locationIds?.[0] || DefaultValues.basicInfo.prefectureId,
      publication: {
        since:
          data?.publication.since || DefaultValues.basicInfo.publication.since,
        status:
          data?.publication.status ||
          DefaultValues.basicInfo.publication.status,
        until:
          data?.publication.until || DefaultValues.basicInfo.publication.until,
      },
      salesDateSeason:
        customFields.salesDateSeason || DefaultValues.basicInfo.salesDateSeason,
      salesDateType: customFields.salesStartDate
        ? isFormatDay(customFields.salesStartDate)
          ? DateType.DAY
          : DateType.MONTH
        : DefaultValues.basicInfo.salesDateType,
      salesEndDate:
        dateConvertToFormat(
          customFields.salesEndDate,
          isFormatDay(customFields.salesEndDate) ? 'yyyy/MM/dd' : 'yyyy/MM'
        ) || DefaultValues.basicInfo.salesEndDate,
      salesHouseCount:
        customFields.salesHouseCount ||
        DefaultValues.buildingInfo.salesHouseCount,
      salesScheduleComment:
        customFields.salesScheduleComment ||
        DefaultValues.basicInfo.salesScheduleComment,
      salesStartDate:
        dateConvertToFormat(
          customFields.salesStartDate,
          isFormatDay(customFields.salesStartDate) ? 'yyyy/MM/dd' : 'yyyy/MM'
        ) || DefaultValues.basicInfo.salesStartDate,
      salesStatus:
        customFields?.salesStatus || DefaultValues.overview.salesStatus,
      salesType: customFields.salesType || DefaultValues.basicInfo.salesType,
      transportMainLine:
        customFields?.transportMainLine ||
        DefaultValues.basicInfo.transportMainLine,
      transportMainMean:
        customFields?.transportMainMean ||
        DefaultValues.basicInfo.transportMainMean,
      transportMainNearest:
        customFields?.transportMainNearest ||
        DefaultValues.basicInfo.transportMainNearest,
      transportMainNote:
        customFields?.transportMainNote ||
        DefaultValues.basicInfo.transportMainNote,
      transportMainTimeMax: customFields?.transportMainTimeMax
        ? String(customFields.transportMainTimeMax)
        : DefaultValues.basicInfo.transportMainTimeMax,
      transportMainTimeMin: customFields?.transportMainTimeMin
        ? String(customFields.transportMainTimeMin)
        : DefaultValues.basicInfo.transportMainTimeMin,
      transportSub,
      transportationFlag:
        customFields?.transportationFlag ||
        DefaultValues.basicInfo.transportationFlag,
    });

    priceForm.reset({
      facilityCost:
        customFields.facilityCost || DefaultValues.price.facilityCost,
      maxPrice: customFields.maxPrice
        ? String(customFields.maxPrice)
        : DefaultValues.price.maxPrice,
      minPrice: customFields.minPrice
        ? String(customFields.minPrice)
        : DefaultValues.price.minPrice,
      mostPriceRangeAdditional:
        customFields.mostPriceRangeAdditional ||
        DefaultValues.price.mostPriceRangeAdditional,
      mostPriceRangeCount:
        customFields.mostPriceRangeCount ||
        DefaultValues.price.mostPriceRangeCount,
      mostPriceRanges:
        customFields.mostPriceRanges || DefaultValues.price.mostPriceRanges,
      otherCost: customFields.otherCost || DefaultValues.price.otherCost,
      priceAdditional:
        customFields.priceAdditional || DefaultValues.price.priceAdditional,
      priceNote: customFields.priceNote || DefaultValues.price.priceNote,
      priceRangeType:
        customFields.priceRangeType || DefaultValues.price.priceRangeType,
      priceStatus: customFields.priceStatus || DefaultValues.price.priceStatus,
      salesHouseCount:
        customFields.salesHouseCount ||
        DefaultValues.buildingInfo.salesHouseCount,
      salesStatus:
        customFields?.salesStatus || DefaultValues.overview.salesStatus,
    });

    landInfoForm.reset({
      areaRatio: customFields.areaRatio || DefaultValues.landInfo.areaRatio,
      buildingPermitReasonType:
        customFields.buildingPermitReasonType ||
        DefaultValues.landInfo.buildingPermitReasonType,
      facility: customFields.facility || DefaultValues.landInfo.facility,
      landAreaRangeType:
        customFields.landAreaRangeType ||
        DefaultValues.landInfo.landAreaRangeType,
      landAreaType:
        customFields.landAreaType || DefaultValues.landInfo.landAreaType,
      landCategory:
        customFields.landCategory || DefaultValues.landInfo.landCategory,
      landLeaseholdCost:
        customFields.landLeaseholdCost ||
        DefaultValues.landInfo.landLeaseholdCost,
      landLeaseholdDuration:
        customFields.landLeaseholdDuration ||
        DefaultValues.landInfo.landLeaseholdDuration,
      landLeaseholdRent:
        customFields.landLeaseholdRent ||
        DefaultValues.landInfo.landLeaseholdRent,
      landRightType:
        customFields.landRightType || DefaultValues.landInfo.landRightType,
      maxLandArea: customFields.maxLandArea
        ? String(customFields.maxLandArea).split('.')
        : DefaultValues.landInfo.maxLandArea,
      maxRoadWidth:
        customFields.maxRoadWidth?.split('.') ||
        DefaultValues.landInfo.maxRoadWidth,
      minLandArea: customFields.minLandArea
        ? String(customFields.minLandArea).split('.')
        : DefaultValues.landInfo.minLandArea,
      minRoadWidth:
        customFields.minRoadWidth?.split('.') ||
        DefaultValues.landInfo.minRoadWidth,
      roadNote: customFields.roadNote || DefaultValues.landInfo.roadNote,
      roadPainting:
        customFields.roadPainting || DefaultValues.landInfo.roadPainting,
      roadWidthRangeType:
        customFields.roadWidthRangeType ||
        DefaultValues.landInfo.roadWidthRangeType,
      tsuboNotationFlag:
        customFields.tsuboNotationFlag ||
        DefaultValues.landInfo.tsuboNotationFlag,
      useDistrict:
        customFields.useDistrict?.[0] || DefaultValues.landInfo.useDistrict,
      useDistricts:
        customFields.useDistrict?.filter((u, i) => i >= 1) ||
        DefaultValues.landInfo.useDistricts,
    });

    // 完成時期
    const completionDateMonth =
      customFields.completionDateType === ProductCompletionDateType.CONTRACTED
        ? customFields.completionDate
        : dateConvertToFormat(customFields.completionDate, 'M');
    const completionDateYear =
      customFields.completionDateType === ProductCompletionDateType.CONTRACTED
        ? DefaultValues.buildingInfo.completionDateYear
        : dateConvertToFormat(customFields.completionDate, 'yyyy');
    // 引渡可能時期
    const deliveryDateMonth =
      customFields.deliveryDateType === ProductDeliveryDateType.CONTRACTED
        ? customFields.deliveryDate
        : dateConvertToFormat(customFields.deliveryDate, 'M');
    const deliveryDateYear =
      customFields.deliveryDateType === ProductDeliveryDateType.CONTRACTED
        ? DefaultValues.buildingInfo.deliveryDateYear
        : dateConvertToFormat(customFields.deliveryDate, 'yyyy');
    buildingInfoForm.reset({
      buildingAreaRangeType:
        customFields.buildingAreaRangeType ||
        DefaultValues.buildingInfo.buildingAreaRangeType,
      buildingAreaType:
        customFields.buildingAreaType ||
        DefaultValues.buildingInfo.buildingAreaType,
      buildingConfirmationNumber:
        customFields.buildingConfirmationNumber ||
        DefaultValues.buildingInfo.buildingConfirmationNumber,
      buildingStatus:
        customFields.buildingStatus ||
        DefaultValues.buildingInfo.buildingStatus,
      buildingSystem:
        customFields.buildingSystem ||
        DefaultValues.buildingInfo.buildingSystem,
      completionDateMonth:
        completionDateMonth || DefaultValues.buildingInfo.completionDateMonth,
      completionDateType:
        customFields.completionDateType ||
        DefaultValues.buildingInfo.completionDateType,
      completionDateYear:
        completionDateYear || DefaultValues.buildingInfo.completionDateYear,
      completionSeason:
        customFields.completionSeason ||
        DefaultValues.buildingInfo.completionSeason,
      constructionCompany:
        customFields.constructionCompany ||
        DefaultValues.buildingInfo.constructionCompany,
      deliveryDateMonth:
        deliveryDateMonth || DefaultValues.buildingInfo.deliveryDateMonth,
      deliveryDateType:
        customFields.deliveryDateType ||
        DefaultValues.buildingInfo.deliveryDateType,
      deliveryDateYear:
        deliveryDateYear || DefaultValues.buildingInfo.deliveryDateYear,
      deliveryNote:
        customFields.deliveryNote || DefaultValues.buildingInfo.deliveryNote,
      deliverySeason:
        customFields.deliverySeason ||
        DefaultValues.buildingInfo.deliverySeason,
      floorPlanRangeType:
        customFields.floorPlanRangeType ||
        DefaultValues.buildingInfo.floorPlanRangeType,
      floorPlanRoomsMax: customFields.floorPlanRooms
        ? String(customFields.floorPlanRooms.slice(-1)[0] || '')
        : DefaultValues.buildingInfo.floorPlanRoomsMax,
      floorPlanRoomsMin: customFields.floorPlanRooms
        ? String(customFields.floorPlanRooms[0] || '')
        : DefaultValues.buildingInfo.floorPlanRoomsMin,
      floorPlanTypeAdditional:
        customFields.floorPlanTypeAdditional ||
        DefaultValues.buildingInfo.floorPlanTypeAdditional,
      floorPlanTypeMax:
        customFields.floorPlanTypeMax ||
        DefaultValues.buildingInfo.floorPlanTypeMax,
      floorPlanTypeMin:
        customFields.floorPlanTypeMin ||
        DefaultValues.buildingInfo.floorPlanTypeMin,
      maxBuildingArea: customFields.maxBuildingArea
        ? String(customFields.maxBuildingArea).split('.')
        : DefaultValues.buildingInfo.maxBuildingArea,
      minBuildingArea: customFields.minBuildingArea
        ? String(customFields.minBuildingArea).split('.')
        : DefaultValues.buildingInfo.minBuildingArea,
      reformExterior:
        customFields.reformExterior ||
        DefaultValues.buildingInfo.reformExterior,
      reformInterior:
        customFields.reformInterior ||
        DefaultValues.buildingInfo.reformInterior,
      salesHouseCount:
        customFields.salesHouseCount ||
        DefaultValues.buildingInfo.salesHouseCount,
      salesHouseStatus:
        customFields.salesHouseStatus ||
        DefaultValues.buildingInfo.salesHouseStatus,
      totalHouseCount:
        customFields.totalHouseCount ||
        DefaultValues.buildingInfo.totalHouseCount,
      transactionExpirationDate:
        dateConvertToFormat(customFields.transactionExpirationDate) ||
        DefaultValues.buildingInfo.transactionExpirationDate,
    });

    companyInfoForm.reset({
      companyAddress:
        customFields.companyAddress || DefaultValues.companyInfo.companyAddress,
      companyName:
        customFields.companyName || DefaultValues.companyInfo.companyName,
      conditionsTransaction:
        customFields.conditionsTransaction ||
        DefaultValues.companyInfo.conditionsTransaction,
      conditionsTransactionNote:
        customFields.conditionsTransactionNote ||
        DefaultValues.companyInfo.conditionsTransactionNote,
      groupName: customFields.groupName || DefaultValues.companyInfo.groupName,
      licenseNumber:
        customFields.licenseNumber || DefaultValues.companyInfo.licenseNumber,
      salesHouseCount:
        customFields.salesHouseCount ||
        DefaultValues.buildingInfo.salesHouseCount,
    });

    limitationForm.reset({
      legalLimitations:
        customFields.legalLimitations ||
        DefaultValues.limitation.legalLimitations,
      legalOtherLimitationNote:
        customFields.legalOtherLimitationNote ||
        DefaultValues.limitation.legalOtherLimitationNote,
      legalOtherLimitationType:
        customFields.legalOtherLimitationType ||
        DefaultValues.limitation.legalOtherLimitationType,
      legalOtherLimitations:
        customFields.legalOtherLimitations ||
        DefaultValues.limitation.legalOtherLimitations,
    });

    eventInfoForm.reset({
      eventAction:
        customFields.eventAction || DefaultValues.eventInfo.eventAction,
      eventContent:
        customFields.eventContent || DefaultValues.eventInfo.eventContent,
      eventDateList:
        customFields.eventDateList || DefaultValues.eventInfo.eventDateList,
      eventDateSince:
        dateConvertToFormat(customFields.eventDateSince) ||
        DefaultValues.eventInfo.eventDateSince,
      eventDateType:
        customFields.eventDateType || DefaultValues.eventInfo.eventDateType,
      eventDateUntil:
        dateConvertToFormat(customFields.eventDateUntil) ||
        DefaultValues.eventInfo.eventDateUntil,
      eventName: customFields.eventName || DefaultValues.eventInfo.eventName,
      eventTimeSince: customFields.eventTimeSince
        ? customFields.eventTimeSince?.split(':')
        : DefaultValues.eventInfo.eventTimeSince,
      eventTimeUntil: customFields.eventTimeUntil
        ? customFields.eventTimeUntil?.split(':')
        : DefaultValues.eventInfo.eventTimeUntil,
    });

    aroundForm.reset({
      arounds: data?.variants
        ? getVariantsBySku(data?.variants, ProductVariantSku.AROUND)
        : DefaultValues.around.arounds,
    });

    equipmentForm.reset({
      equipments: data?.variants
        ? getVariantsBySku(data?.variants, ProductVariantSku.EQUIPMENT)
        : DefaultValues.equipment.equipments,
    });

    exteriorForm.reset({
      exteriors: data?.variants
        ? getVariantsBySku(data?.variants, ProductVariantSku.EXTERIOR)
        : DefaultValues.exterior.exteriors,
    });

    const floorPlans = getVariantsBySku(
      data?.variants,
      ProductVariantSku.FLOOR_PLAN
    );
    floorPlanForm.reset({
      floorPlans: floorPlans?.length
        ? floorPlans.map((v) => {
            const defaultValue = ['', ''];
            return {
              ...v,
              customFields: {
                ...v.customFields,
                buildingArea:
                  v.customFields?.buildingArea?.split('.') || defaultValue,
                landArea: v.customFields?.landArea?.split('.') || defaultValue,
              },
            };
          })
        : DefaultValues.floorPlan.floorPlans,
    });

    interiorForm.reset({
      interiors: data?.variants
        ? getVariantsBySku(data?.variants, ProductVariantSku.INTERIOR)
        : DefaultValues.interior.interiors,
    });

    const plotMaps = getVariantsBySku(
      data?.variants,
      ProductVariantSku.PLOT_MAP
    );
    plotMapForm.reset({
      plotMaps: plotMaps?.length ? plotMaps : DefaultValues.plotMap.plotMaps,
    });
  };

  const getUpdatePayload = async (): Promise<ProductUpdate> => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }

    const mainVisuals = await Promise.all(
      mainVisualForm.getValues().mainVisuals.map(async (value, index) => {
        let id = value.id;
        if (!id) {
          const blob = await base64ToBlob(value.url);
          const attachment = await uploadBlob(organizationId, blob);
          id = attachment.id;
          mainVisualForm.setValue(`mainVisuals.${index}.id`, id);
        }
        return { id, key: index };
      })
    );

    const variants: ProductVariantCreate[] = [];
    const aroundValue = aroundForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        aroundValue.arounds,
        ProductVariantSku.AROUND
      ))
    );
    const equipmentValue = equipmentForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        equipmentValue.equipments,
        ProductVariantSku.EQUIPMENT
      ))
    );
    const exteriorValue = exteriorForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        exteriorValue.exteriors,
        ProductVariantSku.EXTERIOR
      ))
    );
    const floorPlanValue = floorPlanForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        floorPlanValue.floorPlans,
        ProductVariantSku.FLOOR_PLAN
      ))
    );
    const interiorValue = interiorForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        interiorValue.interiors,
        ProductVariantSku.INTERIOR
      ))
    );
    const plotMapValue = plotMapForm.getValues();
    variants.push(
      ...(await getVariantPayloadBySku(
        plotMapValue.plotMaps,
        ProductVariantSku.PLOT_MAP
      ))
    );

    const categoryId =
      categories.find((c) => c.name === ProductCategory.PROPERTY)?.id || '';

    const overviewValue = overviewForm.getValues();
    const basicInfoValue = basicInfoForm.getValues();
    const priceValue = priceForm.getValues();
    const landInfoValue = landInfoForm.getValues();
    const buildingInfoValue = buildingInfoForm.getValues();
    const companyInfoValue = companyInfoForm.getValues();
    const limitationValue = limitationForm.getValues();
    const eventInfoValue = eventInfoForm.getValues();

    // 間取りの部屋数一覧
    const floorPlanRooms = buildingInfoValue.floorPlanRoomsMax
      ? [
          Number(buildingInfoValue.floorPlanRoomsMin),
          Number(buildingInfoValue.floorPlanRoomsMax),
        ].sort()
      : [Number(buildingInfoValue.floorPlanRoomsMin)];
    // 建物 完成時期
    const completionDate =
      buildingInfoValue.completionDateType ===
      ProductCompletionDateType.CONTRACTED
        ? buildingInfoValue.completionDateMonth
        : buildingInfoValue.completionDateYear &&
          buildingInfoValue.completionDateMonth
        ? [
            buildingInfoValue.completionDateYear,
            buildingInfoValue.completionDateMonth.padStart(2, '0'),
          ].join('/')
        : '';
    // 建物 引渡可能時期
    const deliveryDate =
      buildingInfoValue.deliveryDateType === ProductDeliveryDateType.CONTRACTED
        ? buildingInfoValue.deliveryDateMonth
        : buildingInfoValue.deliveryDateYear &&
          buildingInfoValue.deliveryDateMonth
        ? [
            buildingInfoValue.deliveryDateYear,
            buildingInfoValue.deliveryDateMonth.padStart(2, '0'),
          ].join('/')
        : '';

    return {
      additionalInformation: overviewValue.additionalInformation,
      addressLine1: basicInfoValue.addressLine1,
      addressLine2: basicInfoValue.addressLine2,
      addressLine3: basicInfoValue.addressLine3,
      categoryId,
      customFields: {
        addressLine3HiddenFlag: basicInfoValue.addressLine3HiddenFlag,
        addressLine4: basicInfoValue.addressLine4,
        areaRatio: landInfoValue.areaRatio,
        buildingAreaRangeType: buildingInfoValue.buildingAreaRangeType,
        buildingAreaType: buildingInfoValue.buildingAreaType,
        buildingConfirmationNumber:
          buildingInfoValue.buildingConfirmationNumber,
        buildingPermitReasonType: landInfoValue.buildingPermitReasonType,
        buildingStatus: buildingInfoValue.buildingStatus,
        buildingSystem: buildingInfoValue.buildingSystem,
        category: ProductCategory.PROPERTY,
        companyAddress: companyInfoValue.companyAddress,
        companyName: companyInfoValue.companyName,
        completionDate,
        completionDateType: buildingInfoValue.completionDateType,
        completionSeason: buildingInfoValue.completionSeason,
        conditionsTransaction: companyInfoValue.conditionsTransaction,
        conditionsTransactionNote: companyInfoValue.conditionsTransactionNote,
        constructionCompany: buildingInfoValue.constructionCompany,
        contactAdditional: basicInfoValue.contactAdditional,
        contactAddress: basicInfoValue.contactAddress,
        contactPhoneNumber: basicInfoValue.contactPhoneNumber.join('-'),
        deliveryDate,
        deliveryDateType: buildingInfoValue.deliveryDateType,
        deliveryNote: buildingInfoValue.deliveryNote,
        deliverySeason: buildingInfoValue.deliverySeason,
        eventAction: eventInfoValue.eventAction,
        eventContent: eventInfoValue.eventContent,
        eventDateList: eventInfoValue.eventDateList.filter((i) => !!i),
        eventDateSince: dateConvertToFormat(eventInfoValue.eventDateSince),
        eventDateType: eventInfoValue.eventDateType,
        eventDateUntil: dateConvertToFormat(eventInfoValue.eventDateUntil),
        eventName: eventInfoValue.eventName,
        eventTimeSince: eventInfoValue.eventTimeSince.some((l) => !!l)
          ? eventInfoValue.eventTimeSince
              .map((e) => e.padStart(2, '0'))
              .join(':')
          : '',
        eventTimeUntil: eventInfoValue.eventTimeUntil.some((l) => !!l)
          ? eventInfoValue.eventTimeUntil
              .map((e) => e.padStart(2, '0'))
              .join(':')
          : '',
        facility: landInfoValue.facility,
        facilityCost: priceValue.facilityCost,
        floorPlanRangeType: buildingInfoValue.floorPlanRangeType,
        floorPlanRooms:
          floorPlanRooms.length >= 2 &&
          buildingInfoValue.floorPlanRangeType === ProductRangeType.BETWEEN
            ? Array.from(
                {
                  length: floorPlanRooms[1] - floorPlanRooms[0] + 1,
                },
                (n, i) => i + floorPlanRooms[0]
              )
            : floorPlanRooms,
        floorPlanTypeAdditional: buildingInfoValue.floorPlanTypeAdditional,
        floorPlanTypeMax:
          buildingInfoValue.floorPlanRoomsMax &&
          buildingInfoValue.floorPlanRoomsMin >
            buildingInfoValue.floorPlanRoomsMax
            ? buildingInfoValue.floorPlanTypeMin
            : buildingInfoValue.floorPlanTypeMax,
        floorPlanTypeMin:
          buildingInfoValue.floorPlanRoomsMax &&
          buildingInfoValue.floorPlanRoomsMin >
            buildingInfoValue.floorPlanRoomsMax
            ? buildingInfoValue.floorPlanTypeMax
            : buildingInfoValue.floorPlanTypeMin,
        groupName: companyInfoValue.groupName,
        landAreaRangeType: landInfoValue.landAreaRangeType,
        landAreaType: landInfoValue.landAreaType,
        landCategory: landInfoValue.landCategory,
        landLeaseholdCost: landInfoValue.landLeaseholdCost,
        landLeaseholdDuration: landInfoValue.landLeaseholdDuration,
        landLeaseholdRent: landInfoValue.landLeaseholdRent,
        landRightType: landInfoValue.landRightType,
        legalLimitations: limitationValue.legalLimitations,
        legalOtherLimitationNote: limitationValue.legalOtherLimitationNote,
        legalOtherLimitationType: limitationValue.legalOtherLimitationType,
        legalOtherLimitations: limitationValue.legalOtherLimitations,
        licenseNumber: companyInfoValue.licenseNumber,
        lotteryDate: dateConvertToFormat(
          basicInfoValue.lotteryDate,
          basicInfoValue.lotteryDateType === DateType.DAY
            ? 'yyyy/MM/dd'
            : 'yyyy/MM'
        ),
        lotteryDateSeason: basicInfoValue.lotteryDateSeason,
        maxBuildingArea: Number(
          buildingInfoValue.maxBuildingArea.map((i) => i || 0).join('.')
        ),
        maxLandArea: Number(
          landInfoValue.maxLandArea.map((i) => i || 0).join('.')
        ),
        maxPrice: Number(priceValue.maxPrice),
        maxRoadWidth: landInfoValue.maxRoadWidth.some((m) => m !== '')
          ? landInfoValue.maxRoadWidth.map((i) => i || '0').join('.')
          : '',
        minBuildingArea: Number(
          buildingInfoValue.minBuildingArea.map((i) => i || 0).join('.')
        ),
        minLandArea: Number(
          landInfoValue.minLandArea.map((i) => i || 0).join('.')
        ),
        minPrice: Number(priceValue.minPrice),
        minRoadWidth: landInfoValue.minRoadWidth.some((m) => m !== '')
          ? landInfoValue.minRoadWidth.map((i) => i || '0').join('.')
          : '',
        mostPriceRangeAdditional: priceValue.mostPriceRangeAdditional,
        mostPriceRangeCount: priceValue.mostPriceRangeCount,
        mostPriceRanges: priceValue.mostPriceRanges,
        otherCost: priceValue.otherCost,
        previewAdComment: overviewValue.previewAdComment,
        priceAdditional: priceValue.priceAdditional,
        priceNote: priceValue.priceNote,
        priceRangeType: priceValue.priceRangeType,
        priceStatus: priceValue.priceStatus,
        propertyCategory: overviewValue.propertyCategory,
        reformExterior: buildingInfoValue.reformExterior,
        reformInterior: buildingInfoValue.reformInterior,
        roadNote: landInfoValue.roadNote,
        roadPainting: landInfoValue.roadPainting,
        roadWidthRangeType: landInfoValue.roadWidthRangeType,
        salesDateSeason: basicInfoValue.salesDateSeason,
        salesEndDate: dateConvertToFormat(
          basicInfoValue.salesEndDate,
          basicInfoValue.salesDateType === DateType.DAY
            ? 'yyyy/MM/dd'
            : 'yyyy/MM'
        ),
        salesHouseCount: buildingInfoValue.salesHouseCount,
        salesHouseStatus: buildingInfoValue.salesHouseStatus,
        salesScheduleComment: basicInfoValue.salesScheduleComment,
        salesStartDate: dateConvertToFormat(
          basicInfoValue.salesStartDate,
          basicInfoValue.salesDateType === DateType.DAY
            ? 'yyyy/MM/dd'
            : 'yyyy/MM'
        ),
        salesStatus: overviewValue.salesStatus,
        salesType: basicInfoValue.salesType,
        totalHouseCount: buildingInfoValue.totalHouseCount,
        transactionExpirationDate: dateConvertToFormat(
          buildingInfoValue.transactionExpirationDate
        ),
        transportMainLine: basicInfoValue.transportMainLine,
        transportMainMean: basicInfoValue.transportMainMean,
        transportMainNearest: basicInfoValue.transportMainNearest,
        transportMainNote: basicInfoValue.transportMainNote,
        transportMainTimeMax: Number(basicInfoValue.transportMainTimeMax),
        transportMainTimeMin: Number(basicInfoValue.transportMainTimeMin),
        transportSub1Line: basicInfoValue.transportSub[0]?.line || '',
        transportSub1Mean: basicInfoValue.transportSub[0]?.mean || Common.EMPTY,
        transportSub1Nearest: basicInfoValue.transportSub[0]?.nearest || '',
        transportSub1Note: basicInfoValue.transportSub[0]?.note || '',
        transportSub1TimeMax: Number(basicInfoValue.transportSub[0]?.timeMax),
        transportSub1TimeMin: Number(basicInfoValue.transportSub[0]?.timeMin),
        transportSub2Line: basicInfoValue.transportSub[1]?.line || '',
        transportSub2Mean: basicInfoValue.transportSub[1]?.mean || Common.EMPTY,
        transportSub2Nearest: basicInfoValue.transportSub[1]?.nearest || '',
        transportSub2Note: basicInfoValue.transportSub[1]?.note || '',
        transportSub2TimeMax: Number(basicInfoValue.transportSub[1]?.timeMax),
        transportSub2TimeMin: Number(basicInfoValue.transportSub[1]?.timeMin),
        transportationFlag: basicInfoValue.transportationFlag,
        tsuboNotationFlag: landInfoValue.tsuboNotationFlag,
        useDistrict: [landInfoValue.useDistrict, ...landInfoValue.useDistricts],
      },
      description: overviewValue.description,
      features: overviewValue.features.map((f) => {
        return { name: '', value: f.value };
      }),
      geoCoordinatesField: {
        coordinates: [
          basicInfoValue.geoCode?.lat || 0,
          basicInfoValue.geoCode?.lng || 0,
        ],
        type: ProductGeoCoordinatesFieldType.POINT,
      },
      imageIds: sortBy(
        mainVisuals.filter((i) => !!i.id),
        'key'
      ).map((i) => i.id),
      locationIds: [basicInfoValue.prefectureId, basicInfoValue.cityId].filter(
        (l) => !!l
      ),
      name: overviewValue.name,
      postalCode: basicInfoValue.postalCode,
      publication: {
        since: getPublicationSince(
          basicInfoValue.publication.status,
          basicInfoValue.publication.since || undefined
        ),
        status: basicInfoValue.publication.status,
        until: getPublicationUntil(
          basicInfoValue.publication.status,
          basicInfoValue.publication.until || undefined,
          true
        ),
      },
      variants,
    };
  };

  const getVariantPayloadBySku = async (
    variant: (VariantEditFormKey | PropertyFloorPlanEditFormKey)[],
    sku: ProductVariantSkuKey
  ): Promise<ProductVariantCreate[]> => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }

    const images = await Promise.all(
      variant.map(async (value, index) => {
        if (value.url && !value.imageId) {
          const blob = await base64ToBlob(value.url);
          const attachment = await uploadBlob(organizationId, blob);
          value.imageId = attachment.id;
        }

        return {
          ...value,
          imageIds: value.imageId ? [value.imageId] : [],
          key: index,
        };
      })
    );

    return sortBy(images, 'key').map((value) => {
      let customFields: VariantCustomFields | undefined = {
        category: value.customFields?.category || '',
      };
      switch (sku) {
        case ProductVariantSku.FLOOR_PLAN:
          customFields = {
            ...customFields,
            buildingArea:
              (value as PropertyFloorPlanEditFormKey).customFields?.buildingArea
                ?.filter((i) => i !== '')
                .join('.') || '',
            comment: value.customFields?.comment || '',
            landArea:
              (value as PropertyFloorPlanEditFormKey).customFields?.landArea
                ?.filter((i) => i !== '')
                .join('.') || '',
            price: value.customFields?.price || '',
          };
          break;
      }

      return {
        customFields,
        description: value.description || '',
        imageIds: value.imageIds || [],
        price: { amount: 0 },
        sku,
        title: value.title || '-',
      };
    });
  };

  const sendFormValues = async (id?: string) => {
    if (!organizationId) {
      throw new Error('所属IDが指定されていません');
    }
    try {
      setLoadingState(true);
      let updatedData;
      if (id) {
        const { data } = await updateProduct(id, await getUpdatePayload());
        updatedData = data;
      } else {
        const { data } = await createProduct(
          organizationId,
          (await getUpdatePayload()) as ProductCreate
        );
        updatedData = data;
      }
      return updatedData;
    } catch (error) {
      if (isError(error)) {
        throw new Error(error.message);
      }
    } finally {
      setLoadingState(false);
    }
  };

  const triggerFormAll = async () => {
    const triggers: Array<() => Promise<boolean>> = [
      overviewForm.trigger,
      mainVisualForm.trigger,
      basicInfoForm.trigger,
      priceForm.trigger,
      landInfoForm.trigger,
      buildingInfoForm.trigger,
      companyInfoForm.trigger,
      limitationForm.trigger,
      eventInfoForm.trigger,
      aroundForm.trigger,
      equipmentForm.trigger,
      floorPlanForm.trigger,
      plotMapForm.trigger,
      exteriorForm.trigger,
      interiorForm.trigger,
    ];
    const results = await Promise.all(triggers.map((trigger) => trigger()));
    return results.every((result) => result);
  };

  // 掲載に変更するためのデータチェック
  const checkPublishToActive = () => {
    // 販売状況チェック
    const salesStatus = overviewForm.getValues('salesStatus');
    if (
      salesStatus &&
      (salesStatus === ProductSalesStatus.SOLD_OUT ||
        salesStatus === ProductSalesStatus.SELLING_STOP ||
        salesStatus === ProductSalesStatus.SECRET)
    ) {
      throw new Error(
        '販売状態が 「完売・成約」「売り止め」「掲載止め」のいずれかのため掲載できません'
      );
    }

    // 取引有効期限チェック
    const expirationDate = buildingInfoForm.getValues(
      'transactionExpirationDate'
    );
    if (
      expirationDate &&
      isBefore(new Date(expirationDate), addDays(startOfDay(new Date()), 1))
    ) {
      throw new Error('取引有効期限を過ぎているため掲載できません');
    }
  };

  const isValid = useMemo(() => {
    return (
      overviewForm.formState.isValid &&
      mainVisualForm.formState.isValid &&
      basicInfoForm.formState.isValid &&
      priceForm.formState.isValid &&
      landInfoForm.formState.isValid &&
      buildingInfoForm.formState.isValid &&
      companyInfoForm.formState.isValid &&
      limitationForm.formState.isValid &&
      eventInfoForm.formState.isValid &&
      aroundForm.formState.isValid &&
      equipmentForm.formState.isValid &&
      exteriorForm.formState.isValid &&
      floorPlanForm.formState.isValid &&
      plotMapForm.formState.isValid &&
      interiorForm.formState.isValid
    );
  }, [
    overviewForm.formState.isValid,
    mainVisualForm.formState.isValid,
    basicInfoForm.formState.isValid,
    priceForm.formState.isValid,
    landInfoForm.formState.isValid,
    buildingInfoForm.formState.isValid,
    companyInfoForm.formState.isValid,
    limitationForm.formState.isValid,
    eventInfoForm.formState.isValid,
    aroundForm.formState.isValid,
    equipmentForm.formState.isValid,
    exteriorForm.formState.isValid,
    floorPlanForm.formState.isValid,
    plotMapForm.formState.isValid,
    interiorForm.formState.isValid,
  ]);

  return {
    aroundForm,
    basicInfoForm,
    buildingInfoForm,
    checkPublishToActive,
    companyInfoForm,
    equipmentForm,
    eventInfoForm,
    exteriorForm,
    floorPlanForm,
    interiorForm,
    isValid,
    landInfoForm,
    limitationForm,
    mainVisualForm,
    overviewForm,
    plotMapForm,
    priceForm,
    sendFormValues,
    setFormValues,
    setOrganizationId,
    triggerFormAll,
  };
};
